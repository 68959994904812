import './App.css';
import Themeroutes from "./routes/Router";
import AppContext from "./context/AppContext";
import React, { useState } from 'react';
import { useRoutes } from "react-router-dom";

function App() {
  const routing = useRoutes(Themeroutes);

  return <AppContext.Provider value={{}}>
    <div className="dark">{routing}</div>
  </AppContext.Provider>
}

export default App;
