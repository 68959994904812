import { lazy } from "react";
import { Navigate } from "react-router-dom";

/****Layouts*****/
const HomeLayout = lazy(() => import("../layouts/HomeLayout.js"));
const AdmiHomeLayout = lazy(() => import("../layouts/AdminHomeLayout.js"));
/*****Routes******/

/**Pages*/
const HomePublicView = lazy(() => import("../views/HomePublicView.js"));
const CurriculumDetailsView = lazy(() => import("../views/CurriculumDetailsView.js"));

const CurriculumBuilderView = lazy(() => import("../views/admin/CurriculumBuilderView.js"));
const FormBuilderView = lazy(() => import("../views/admin/FormBuilderView.js"));



const ThemeRoutes = [
  {
    path: "/",
    element: <HomeLayout />,
    children: [
      { path: "", exact: true, element: <HomePublicView /> },
      { path: "/curriculum/*", exact: true, element: <CurriculumDetailsView /> },
    ],
  },
  {
    path: "/admin",
    element: <AdmiHomeLayout />,
    children: [
      { path: "", exact: true,  element: <Navigate to="formBuilder" replace />},
      { path: "curriculumBuilder", exact: true, element: <CurriculumBuilderView /> },
      { path: "formBuilder", exact: true, element: <FormBuilderView /> },
    ]
  }
];

export default ThemeRoutes;
